import Home from '../views/pages/overview.js';
import Login from '../views/pages/login.js';
import Register from '../views/pages/register.js';
import ForgotPassword from '../views/pages/forgot-password.js';
import RoutingMail from '../views/pages/routing-mail.js';
import DokumenValid from '../views/pages/dokumen-valid.js';
import KodeWilayah from '../views/pages/kode_wilayah.js';
import DokumenInvalid from '../views/pages/dokumen-invalid.js';
import ResetPassword from '../views/pages/reset-password.js';
import RekomSTRTTK from '../views/pages/menu_anggota/rekom-strttk.js';
import RekomSIPTTK from '../views/pages/menu_anggota/rekom-sipttk.js';
import Iuran from '../views/pages/menu_anggota/iuran-anggota.js';
import PengajuanMutasi from '../views/pages/menu_anggota/pengajuan-mutasi.js';
import AnggotaPC from '../views/pages/menu_pengurus_pc/anggota.js';
import AnggotaPD from '../views/pages/menu_pengurus_pd/anggota.js';
import AnggotaPP from '../views/pages/menu_pengurus_pp/anggota.js';
import RegistrasiAnggotaBaru from '../views/pages/menu_pengurus_pp/regist-anggota-baru.js';
import SKPSaya from '../views/pages/menu_anggota/skp-saya.js';
import SKPAnggotaPC from '../views/pages/menu_pengurus_pc/skp-anggota-pc.js';
import SKPAnggotaPD from '../views/pages/menu_pengurus_pd/skp-anggota-pd.js';
import SERKOMPC from '../views/pages/menu_pengurus_pc/serkom-anggota-pc.js';
import SERKOMPD from '../views/pages/menu_pengurus_pd/serkom-anggota-pd.js';
import PermohonanSerkomSKP from '../views/pages/menu_anggota/serkom-skp.js';
import UpdatePassword from '../views/pages/menu_anggota/update-password.js';

import IuranAnggotaPC from '../views/pages/menu_pengurus_pc/iuran-anggota-pc.js';
import SharingProfitPC from '../views/pages/menu_pengurus_pc/sharing-profit-pc.js';
import StatistikPD from '../views/pages/menu_pengurus_pd/statistik-iuran-pd.js';
import StatistikPP from '../views/pages/menu_pengurus_pp/statistik-iuran-pp.js';
import PengajuanSeminarPC from '../views/pages/seminar/pengajuan-seminar-pc.js';

import STRTTKPD from '../views/pages/menu_pengurus_pd/strttk-anggota-pd.js';
import STRTTKPC from '../views/pages/menu_pengurus_pc/strttk-anggota-pc.js';

import SIPTTKPC from '../views/pages/menu_pengurus_pc/sipttk-anggota-pc.js';



import PERIODE_KEPENGURUSAN_PC from '../views/pages/menu_pengurus_pc/periode-kepengurusan-pc.js';
import PENGURUS_PAFI_PC from '../views/pages/menu_pengurus_pc/pengurus-pc.js';
import REGULASI_PENGURUS_PC from '../views/pages/menu_pengurus_pc/regulasi-pengurus-pc.js';
import GENERAL_SETTING_PC from '../views/pages/menu_pengurus_pc/general-setting.js';


import PERIODE_KEPENGURUSAN_PD from '../views/pages/menu_pengurus_pd/periode-kepengurusan-pd.js';
import PENGURUS_PAFI_PD from '../views/pages/menu_pengurus_pd/pengurus-pd.js';
import GENERAL_SETTING_PD from '../views/pages/menu_pengurus_pd/general-setting.js';
import REGULASI_PENGURUS_PD from '../views/pages/menu_pengurus_pd/regulasi-pengurus-pd.js';


import PERIODE_KEPENGURUSAN_PP from '../views/pages/menu_pengurus_pp/periode-kepengurusan-pp.js';
import PENGURUS_PAFI_PP from '../views/pages/menu_pengurus_pp/pengurus-pp.js';
import REGULASI_PENGURUS_PP from '../views/pages/menu_pengurus_pp/regulasi-pengurus-pp.js';

import BeritaPP from '../views/pages/menu_pengurus_pp/berita-pp.js';
import EdaranPP from '../views/pages/menu_pengurus_pp/edaran-pp.js';
import LokerPP from '../views/pages/menu_pengurus_pp/loker-pp.js';


import notFound from '../views/pages/404.js';
import notAccessProfile from '../views/pages/lengkapi-profile.js';
import AssignRole from '../views/pages/menu_pengurus_pp/assign-role.js';
import KelolaAnggotaPP from '../views/pages/menu_pengurus_pp/kelola-anggota-pp.js';
import RekomMutasiPc from '../views/pages/menu_pengurus_pc/rekom-mutasi-pc.js';
import SyaratMutasiPC from '../views/pages/menu_pengurus_pc/syarat-mutasi-pc.js';
import MutasiPCManual from '../views/pages/menu_pengurus_pc/mutasi-manual-pc.js';
import RekomMutasiPd from '../views/pages/menu_pengurus_pd/rekom-mutasi-pd.js';
import NotificationPage from '../views/pages/notification-user.js';
import UploadBuktiBayarRegistrasi from '../views/pages/upload-bukti-bayar-registrasi.js';
import ResendVerification from '../views/pages/resend-verification.js';
import ResendPayment from '../views/pages/resend-payment.js';
import FailedMail from '../views/pages/failed-mail.js';
import UserNotRegistration from '../views/pages/user-not-registration.js';
import kelolaUsersPP from '../views/pages/menu_pengurus_pp/kelola-users-pp.js';



import PengajuanSeminarPD from '../views/pages/seminar/pengajuan-seminar-pd.js';
import PengajuanSeminarPP from '../views/pages/seminar/pengajuan-seminar-pp.js';
import DataPermohonanSeminarPD from '../views/pages/seminar/data-permohonan-seminar-pd.js';
import DataPermohonanSeminarPP from '../views/pages/seminar/data-permohonan-seminar-pp.js';
import DashboardInformation from '../views/pages/dashboard-information.js';
import FormulirSetorPD from '../views/pages/menu_pengurus_pd/formulir-setor.js';
import SettingAksesPP from '../views/pages/setting_akses/setting-akses-pp.js';
import SettingAksesPD from '../views/pages/setting_akses/setting-akses-pd.js';
import GeneralSettingPP from '../views/pages/menu_pengurus_pp/general-setting-pp.js';
import PengajuanKecukupanSKP from '../views/pages/menu_anggota/pengajuan-kecukupan-skp.js';
import KecukupanSKPPC from '../views/pages/kecukupan_skp/kecukupan-skp-pc.js';
import KecukupanSKPPD from '../views/pages/kecukupan_skp/kecukupan-skp-pd.js';
import RequestBlangko from '../views/pages/menu_pengurus_pd/request_blangko.js';
import RequestBlangkoPP from '../views/pages/menu_pengurus_pp/request-blangko-pp.js';
import NotYourTime from '../views/pages/not-your-time.js';
import WilayahTime from '../views/pages/wilayah_time.js';
import LogAktivitas from '../views/pages/super_admin/log-aktivitas.js';

const routes = {
	'/': Home, // default page
	'/dashboard': Home,
	'/login': Login,
	'/rekom-strttk': RekomSTRTTK,
	'/rekom-sipttk': RekomSIPTTK,
	'/register': Register,
	'/sharing-profit-pc': SharingProfitPC,
	'/pengajuan-seminar-pc': PengajuanSeminarPC,
	'/statistic-iuran-pd': StatistikPD,
	'/statistic-iuran-pp': StatistikPP,
	'/sharing-profit-pd': FormulirSetorPD,
	'/update-password': UpdatePassword,
	'/forgot-password': ForgotPassword,
	'/already-success-mail': RoutingMail,
	'/already-verify-mail' : RoutingMail,
	'/dokumen-valid' : DokumenValid,
	'/kode-wilayah' : KodeWilayah,
	'/upload-bukti-bayar-registrasi' : UploadBuktiBayarRegistrasi,
	'/dokumen-invalid' : DokumenInvalid,
	'/reset-password' : ResetPassword,
	'/pengajuan-mutasi' : PengajuanMutasi,
	'/anggota-pc' : AnggotaPC,
	'/anggota-pd' : AnggotaPD,
	'/anggota-pp' : AnggotaPP,
	'/registrasi-anggota-baru' : RegistrasiAnggotaBaru,
	'/skp-saya' : SKPSaya,
	'/skp-anggota-pc' : SKPAnggotaPC,
	'/skp-anggota-pd' : SKPAnggotaPD,
	'/serkom-anggota-pc' : SERKOMPC,
	'/serkom-anggota-pd' : SERKOMPD,
	'/serkom-skp' : PermohonanSerkomSKP,
	'/iuran' : Iuran,
	'/iuran-anggota-pc' : IuranAnggotaPC,

	'/strttk-anggota-pd' : STRTTKPD,
	'/strttk-anggota-pc' : STRTTKPC,
	'/sipttk-anggota-pc' : SIPTTKPC,

	'/periode-kepengurusan-pc' : PERIODE_KEPENGURUSAN_PC, 
	'/pengurus-pc' : PENGURUS_PAFI_PC, 
	'/regulasi-pengurus-pc' : REGULASI_PENGURUS_PC, 
	'/general-setting' : GENERAL_SETTING_PC, 

	
	'/periode-kepengurusan-pd' : PERIODE_KEPENGURUSAN_PD, 
	'/pengurus-pd' : PENGURUS_PAFI_PD, 
	'/regulasi-pengurus-pd' : REGULASI_PENGURUS_PD, 
	'/general-setting-pd' : GENERAL_SETTING_PD, 

	
	'/periode-kepengurusan-pp' : PERIODE_KEPENGURUSAN_PP, 
	'/pengurus-pp' : PENGURUS_PAFI_PP, 
	'/regulasi-pengurus-pp' : REGULASI_PENGURUS_PP,

	'/assign-role':AssignRole,
	'/404' : notFound,
	'/not-access-fitur' : notAccessProfile,
	'/kelola-anggota-pp' : KelolaAnggotaPP,
	'/rekom-mutasi-pc' : RekomMutasiPc,
	'/syarat-mutasi-pc': SyaratMutasiPC,
	'/mutasi-manual-pc' : MutasiPCManual,
	'/rekom-mutasi-pd' : RekomMutasiPd,
	'/notification' : NotificationPage,
	'/resend-verification' : ResendVerification,
	'/resend-payment' : ResendPayment,
	'/expired-verification' : FailedMail,
	'/user-not-registered' : UserNotRegistration,
	'/kelola-users' : kelolaUsersPP,


	'/pengajuan-seminar-pd': PengajuanSeminarPD,
	'/pengajuan-seminar-pp': PengajuanSeminarPP,
	"/data-permohonan-seminar-pp" : DataPermohonanSeminarPP,
	'/data-permohonan-seminar-pd': DataPermohonanSeminarPD,
	'/dashboard-information' : DashboardInformation,
	'/set-akses-pp' : SettingAksesPP,
	'/set-akses-pd' : SettingAksesPD,
	"/general-setting-pp" : GeneralSettingPP,
	"/berita-pp" : BeritaPP,
	"/edaran-pp" : EdaranPP,
	"/loker-pp" : LokerPP,
	// "/kecukupan-skp" : PengajuanKecukupanSKP,
	// "/kecukupan-skp-pc" : KecukupanSKPPC,
	"/kecukupan-skp-pd" : KecukupanSKPPD,
	// "/request-blangko" : RequestBlangko,
	// "/request-blangko-pp" : RequestBlangkoPP,
	"/423": NotYourTime,
	"/wilayah-time":WilayahTime,
	"/log-aktivitas" : LogAktivitas,
};

export default routes;
