let releaseVersion = "release"
let developmentVersion = "development"

const getVersioning = () => {
    return releaseVersion
}

const getBase = () => {
  if (getVersioning() == releaseVersion) {
    return "https://gateway.sipafi.com/api/v1/"
  }else{
    return "https://api-staging.sipafi.com/api/v1/"
  }
}

const getBaseUrlPublic = () => {
  if (getVersioning() == releaseVersion) {
    return "https://gateway.sipafi.com/api/v1/"
  }else{
    return "https://api-staging.sipafi.com/api/v1/"
  }
}

const getSecredId = () => {
  if (getVersioning() == releaseVersion) {
    try {
      const secretId = process.env.SECRET_ID;
      return secretId
    } catch (error) {
      return ""
    }
  }else{
    return "9nKMS4YA9ZzjeA5NAKTn6Zc2EUdatBsOTaGDeHJM"
    // return "vNevQyysOLG0zxHdG9HiJOuX7HPTQLPSvPEABIR7"
  }
}

const getClientId = () => {
  if (getVersioning() == releaseVersion) {
    try {
      const secretId = process.env.CLIENT_ID;
      return secretId
    } catch (error) {
      return ""
    }
  }else{
     return "98885c99-0d1e-4227-ac65-71c8cb04cbef"
    //return "98d134ad-edf0-4444-95df-7670c260496c"
  }
}


const CONFIG = {
  TOKEN: '12345',
  BASE_URL: getBase(),
  CACHE_NAME: {
    prefix: '',
    suffix: 'v1',
    precache: 'app-shell',
  },
  CACHE_KEY_OTENTIKASI : 'login-user-pafi',
  TOKEN_USER : 'login-token-pafi',
  EXPIRED_IN:'expired-token-pafi',
  REFRESH_TOKEN : 'refresh-token-pafi',
  ROLE : 'login-role-pafi',
  CLIENT_ID : getClientId(),
  SECRET_ID : getSecredId(),
  VERSI_APP: 4,
  HARD_VERSION: 5,
  BASE_URL_PUBLIC: getBaseUrlPublic(),
  USER_PC_ID: 'pc_id',
};

export default CONFIG;
