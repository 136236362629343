
import API_ENDPOINT_ANGGOTA from "../../config/globals/menu_anggota_endpoint.js";
import CONFIG from "../../config/globals/config.js";
import ApiAnggota from "../../api/data-anggota.js";
import ApiPengajuanSTRTTK from '../../api/api_pengurus_pc/pengajuan-serkom.js';
import NotificationModal from "../../utils/initial_notification.js";
import UrlParser from "../../routes/url-parser.js";
import ApiNotication from "../../api/data-notification.js";
import MembershipHelper from "../../utils/membership-helper.js";

const RekomSTRTTKPresenter = {

	async init() {
		//await MembershipHelper.scyncMembershipHelper();
		await this._setView();
		await this._getInformation();
		await this._getFormulir();
		await this._getPengajuanSTRTTK();
		await this._uploadBerkas();
		await this._sendPengajuanSTRTTK();
		await this._downloadSTRTTK();
		await this._readNotification();
		await this._download_format();
	},

	async _readNotification() {
		if (UrlParser._getParameterByName("readNotificationID") != null) {
			await ApiNotication.readNotification(UrlParser._getParameterByName("readNotificationID"))
		}
	},

	async _setView() {
		let user = await ApiAnggota.getPrivate();
		if (user.status_code == 200) {
			if (user.data.ktan == "" || user.data.ktan == null || user.data.ktan == undefined) {
				window.location.hash = '#/not-access-fitur';
			} else {
				document.getElementById('main-content').classList.remove('bg-gradient-primary');
				document.getElementById('main-content').style.removeProperty("height");
				document.getElementById('main-content').style.removeProperty("padding-top");
				document.getElementById('content-wrapper').style.removeProperty("overflow-x");
				document.getElementById('appbar').classList.remove('d-none');
				document.getElementById('accordionSidebar').classList.remove('d-none');
				document.getElementById("main-content").style.height = "";
			}
			const eventChangeBerkas= async (y) => {
				let selectTedDom = document.getElementById("selectBerkas")
				let value = selectTedDom.options[selectTedDom.selectedIndex].getAttribute('description-lng')
				if (value == null || value == "") {
					document.getElementById("containerAlertBerkas").classList.add("d-none")
				}else{
					document.getElementById("containerAlertBerkas").classList.remove("d-none")
					document.getElementById("valueAlertBerkas").innerHTML = value
				}
				y.preventDefault();
			}
			document.getElementById("selectBerkas").addEventListener("change", eventChangeBerkas);
		} else {
			window.location.hash = '#/not-access-fitur';
		}
	},

	async _download_format() {

		$(".btn-download").on("click", async function () {
			$(this).html('<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>');
			let documentName = $(this).val();

			const downloadFormat = await ApiAnggota.download_format_syarat({
				nama_dokumen: documentName
			})


			$(this).html('<i class="fas fa-download"></i> DOWNLOAD');
		})
	},

	async _getInformation() {
		$("#information_strttk").html('Loading ...');
		const getInformation = await ApiAnggota.getInformationSTRTTK();
		if (getInformation.status_code == 404) {
			$("#information_strttk").html('Tidak ada informasi !');
		} else {
			$("#information_strttk").html(getInformation.data.description);
		}
	},


	async _getFormulir() {

		$("#textNamaLengkap").val('Loading...');
		const getFormulir = await ApiAnggota.getFormulirSTRTTK();
		let data = getFormulir.data;
		if (data.profil_strttk == null) {
			NotificationModal.show("Anda belum melengkapi profile STRTTK", "warning")
			window.location.hash = '#/not-access-fitur';
		} else {
			let pendidikan = data.pendidikan;
			let pekerjaan = data.pekerjaan;
			let berkas = data.berkas;
			if (data.syarat.serkom_expired == true) {
				$("#textPerpanjangDengan").val("skp")
			} else {
				$("#textPerpanjangDengan").val("serkom")
			}

			berkas.forEach(dataBerkas => {
				let option= new Option(dataBerkas.name, dataBerkas.document_name)
				option.setAttribute("description-lng",dataBerkas.description)
				document.getElementById("selectBerkas").add(option)
			});

			$("#selectJenisPermohonan").change(async function () {
				var jenisPermohonan = $(this).val();


				if (jenisPermohonan == 'renew') {
					$("#Perpanjang").removeClass('d-none');
					$("#strHide").removeClass('d-none');
					$("#textNomorSTRTTK").removeAttr('readonly');
				} else {
					$("#Perpanjang").addClass('d-none');
					$("#strHide").addClass('d-none');
					$("#textNomorSTRTTK").attr('readonly', 'readonly');
				}
			});

			if (pendidikan == null) {
				document.getElementById("selectPendidikan").add(new Option('Data Pendidikan anda Kosong !', ''))
			} else {
				pendidikan.forEach(dataPendidikan => {
					document.getElementById("selectPendidikan").add(new Option(dataPendidikan.jenjang, dataPendidikan.id))
				});
			}
			$("#selectPendidikan").change(async function () {

				$("#InstutisiText").val('Loading...')
				$("#textJenjang").val('Loading...')
				$("#textTahun").val('Loading...')
				$("#textNoIjazah").val('Loading...')

				let id = $(this).val();
				const dataPendidikanByID = await ApiAnggota.getPendidikanByID({ id });
				let dataPendidikan = dataPendidikanByID.data;

				if (dataPendidikanByID.status_code == 200) {
					$("#InstutisiText").val(dataPendidikan.nama_institusi_pendidikan)
					$("#textJenjang").val(dataPendidikan.jenjang)
					$("#textTahun").val(dataPendidikan.tahun_lulus)
					$("#textNoIjazah").val(dataPendidikan.nomor_ijazah)
				} else {
					$("#InstutisiText").val('')
					$("#textJenjang").val('')
					$("#textTahun").val('')
					$("#textNoIjazah").val('')
				}
			});


			if (pekerjaan == null) {
				document.getElementById("selectSarana").add(new Option('Data Pekerjaan anda Kosong !', ''))
			} else {
				pekerjaan.forEach(dataPekerjaan => {
					document.getElementById("selectSarana").add(new Option(dataPekerjaan.nama_tempat_kerja, dataPekerjaan.id))
				});
			}

			$("#selectSarana").change(async function () {

				$("#textJenisTempatKerja").val('Loading...')
				$("#textNamaTempatKerja").val('Loading...')
				$("#TextDariTahun").val('Loading...')
				$("#textAlamatTempatKerja").val('Loading...')
				$("#textJabatanKerja").val('Loading...')

				let id = $(this).val();
				const dataPekerjaanByID = await ApiAnggota.getPekerjaanById(id);
				let dataPekerjaan = dataPekerjaanByID.data;

				if (dataPekerjaanByID.status_code == 200) {
					$("#textJenisTempatKerja").val(dataPekerjaan.jenis_tempat_kerja)
					$("#textNamaTempatKerja").val(dataPekerjaan.nama_tempat_kerja)
					$("#TextDariTahun").val(dataPekerjaan.dari_tahun)
					$("#textAlamatTempatKerja").val(dataPekerjaan.alamat_tempat_kerja)
					$("#textJabatanKerja").val(dataPekerjaan.jabatan)
				} else {
					$("#textJenisTempatKerja").val('')
					$("#textNamaTempatKerja").val('')
					$("#TextDariTahun").val('')
					$("#textAlamatTempatKerja").val('')
					$("#textJabatanKerja").val('')
				}
			});



			if (getFormulir.status_code != 200) {
				NotificationModal.show(`${getFormulir.message}`, 'error');
			} else {
				$("#textNamaLengkap").val(data.name);
				$("#textNomorSTRTTK").val(data.profil_strttk.nomor_strttk);

			}
		}




	},

	async _getPengajuanSTRTTK() {
		let table = await $('#tablePengajuanSTRTTK').DataTable({
			"pageLength": 10,
			"lengthMenu": [10, 25, 50, 100],
			"processing": true,
			"destroy": true,
			'language': {
				'loadingRecords': '&nbsp;',
				'processing': '<div class="spinner"></div>'
			},
			"serverSide": true,
			"searchDelay": 1000,
			"ajax": {
				"url": `${API_ENDPOINT_ANGGOTA.GET_STRTTK}`,
				"type": "GET",
				"beforeSend": function (xhr) {
					xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(CONFIG.TOKEN_USER));
				}
			},
			"columns": [
				{
					data: 'created_at'
				},
				{ data: 'user.name' },
				{ data: 'jenis_permohonan' },
				{
					data: 'berkas_data_umum.ktan_lama', render: function (data) {
						var url = data;
						var content = '';
						if (url == '' || url == null) {
							content = '-';
						} else {
							content = '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}

						return content;
					}
				},
				{
					data: 'berkas_data_umum.ktp_photo', render: function (data) {
						var url = data;
						var content = '';
						if (url == '' || url == null) {
							content = '-';
						} else {
							content = '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}

						return content;
					}
				},
				{
					data: 'berkas_wajib.ijazah', render: function (data) {
						var url = data;
						var content = '';
						if (url == '' || url == null) {
							content = '-';
						} else {
							content = '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}

						return content;
					}
				},
				{
					data: 'berkas_wajib.serkom', render: function (data) {
						var url = data;
						var content = '';
						if (url == '' || url == null) {
							content = '-';
						} else {
							content = '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}

						return content;
					}
				},
				{
					data: 'berkas_wajib.strttk', render: function (data) {
						var url = data;
						var content = '';
						if (url == '' || url == null) {
							content = '-';
						} else {
							content = '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}

						return content;
					}
				},
				{
					data: 'berkas_wajib.surat_permohonan_pc', render: function (data) {
						var url = data;
						var content = '';
						if (url == '' || url == null) {
							content = '-';
						} else {
							content = '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}

						return content;
					}
				},
				{
					data: 'berkas_wajib.surat_keterangan_praktik', render: function (data) {
						var url = data;
						var content = '';
						if (url == '' || url == null) {
							content = '-';
						} else {
							content = '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}

						return content;
					}
				},
				{
					data: 'berkas_wajib.surat_pernyataan_keabsahan_dokumen', render: function (data) {
						var url = data;
						var content = '';
						if (url == '' || url == null) {
							content = '-';
						} else {
							content = '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}

						return content;
					}
				},
				{
					data: 'berkas_wajib.surat_pernyataan_patuh_kode_etik', render: function (data) {
						var url = data;
						var content = '';
						if (url == '' || url == null) {
							content = '-';
						} else {
							content = '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}

						return content;
					}
				},
				{
					data: 'berkas_wajib.surat_pernyataan_sumpah', render: function (data) {
						var url = data;
						var content = '';
						if (url == '' || url == null) {
							content = '-';
						} else {
							content = '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}

						return content;
					}
				},
				{
					data: 'status_pc', render: function (data) {

						if (data == 'pending') {
							var status = `<span class="badge bg-warning text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else if (data == 'revise') {
							var status = `<span class="badge bg-danger text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else {
							var status = `<span class="badge bg-success text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						}

						return status
					}
				},
				{
					data: 'status_pd', render: function (data) {

						if (data == 'pending') {
							var status = `<span class="badge bg-warning text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else if (data == 'revise') {
							var status = `<span class="badge bg-danger text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else {
							var status = `<span class="badge bg-success text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						}

						return status
					}
				}
			],
			order: [
				[0, 'desc']
			],
			"columnDefs": [
				{
					"targets": [0],
					"visible": true,
					"searchable": true,
					"type": "datetime",
				},
				{
					"targets": [3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
					"visible": true,
					"searchable": true,
					className: "text-center"
				},
				{
					"targets": 15,
					"data": null,
					"defaultContent": `
					<div style="display:block">
						<button style="width:100%;" class='mb-1 btn btn-primary btn-sm' id='rekomendasiSTRTTK' title='Download Rekomendasi STRTTK'><i class='fas fa-download'></i> Rekomendasi STRTTK</button>
						<button style="width:100%;" class='mb-1 btn btn-warning btn-sm text-center' data-toggle="modal" data-target="#detailSTRTTKModal" id='detail' title='Detail'><i class="fas fa-list"></i> Detail</button>
						<button style="width:100%;" class='mb-1 btn btn-danger btn-sm' id='delete' title='Hapus Pengajuan'><i class='fas fa-trash'></i> Hapus</button>
					</div>`
				},
			]
		});


		let id = '';
		$('#tablePengajuanSTRTTK tbody').on('click', '#detail', async function () {
			
			$('#btnSendComment').on('click');
			$("#persyaratanSTRTTK").html('');
			$("#dataPendidikanSTRTTK").html('');
			$("#dataPekerjaan").html('');
			let data = table.row($(this).parents('tr')).data();
			id = data.id;


			let table_tracking = await $("#tableHistoryVerifikasiSTRTTK").dataTable({
				"processing": true,
				'language': {
					'loadingRecords': '&nbsp;',
					'processing': '<div class="spinner"></div>'
				},
				"serverSide": true,
				"searchDelay": 1000,
				"ajax": {
					"url": `${API_ENDPOINT_ANGGOTA.GET_TRACKING_STRTTK(id)}`,
					"type": "GET",
					"beforeSend": function (xhr) {
						xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(CONFIG.TOKEN_USER));
					}
				},
				"columns": [
					{
						"data": null,
						"class": "align-top",
						"searchable": false,
						"render": function (data, type, row, meta) {
							return meta.row + meta.settings._iDisplayStart + 1;
						}
					},
					{ data: 'created_at' },
					{ data: 'actor.name' },
					{ data: 'actor_type' },
					{ data: 'description' },
				],

				"order": [[0, 'desc']],
			});

			$("#textHIddenSTRTTK").val(id);

			const getDetailPengajuanSTRTTK = await ApiAnggota.getPengajuanSTRTTKByID({ id });

			let dataDetail = getDetailPengajuanSTRTTK.data;
			if (getDetailPengajuanSTRTTK.status_code == 200) {

				let tanggalPengajuan = dataDetail.created_at;
				let jenisPermohonan = dataDetail.jenis_permohonan;

				if (jenisPermohonan == 'renew') {
					jenisPermohonan = 'Perpanjang';
				} else {
					jenisPermohonan = 'Baru';
				}
				$("#tanggalPengajuan").val(tanggalPengajuan);
				$("#namaAnggota").val(dataDetail.user.name);
				$("#textKTAN").val(dataDetail.user.ktan);
				$("#textEmail").val(dataDetail.user.email);
				$("#textJenisPermohonan").val(jenisPermohonan);
				$("#textPerpanjangDengan").val(dataDetail.perpanjang_dengan);
				$("#textStatus").val(dataDetail.status);

				var dokumen = dataDetail.dokumen
				dokumen.forEach(dataBerkas => {
					var persyaratanSTRTTK = `
						<tr>
							<td>${dataBerkas.name}</td>
							<td width="50%" class="text-center"><img loading="lazy" src='${dataBerkas.dokumen_url}' width="40px"></td>
						</tr>
					`

					$("#persyaratanSTRTTK").append(persyaratanSTRTTK);
				});
				var dokumenPendidikan = dataDetail.pendidikan
				if (dokumenPendidikan == null) {
					var pendidikan = `
					<tr>
						<td colspan="5" align="center">Data Pendidikan Kosong !</td>
					</tr>
				`
				} else {
					var pendidikan = `
						<tr>
							<td>${dokumenPendidikan.tahun_lulus}</td>
							<td>${dokumenPendidikan.jenjang}</td>
							<td>${dokumenPendidikan.nama_institusi_pendidikan}</td>
							<td>${dokumenPendidikan.tanggal_ijazah}</td>
							<td width="50%" class="text-center"><img loading="lazy" src='${dokumenPendidikan.dokumen}' width="40px"></td>
						</tr>
					`
				}

				$("#dataPendidikanSTRTTK").append(pendidikan);


				var dokumenPekerjaan = dataDetail.pekerjaan
				if (dokumenPekerjaan == null) {
					var pekerjaan = `
					<tr>
						<td colspan="10" align="center">Data Pendidikan Kosong !</td>
					</tr>
				`
				} else {
					var pekerjaan = `
						<tr>
							<td>${dokumenPekerjaan.nama_tempat_kerja}</td>
							<td>${dokumenPekerjaan.alamat_tempat_kerja}</td>
							<td>${dokumenPekerjaan.telp_fax}</td>
							<td>${dokumenPekerjaan.phone_number}</td>
							<td>${dokumenPekerjaan.email}</td>
							<td>${dokumenPekerjaan.jabatan}</td>
							<td>${dokumenPekerjaan.jenis_tempat_kerja}</td>
							<td>${dokumenPekerjaan.dari_tahun}</td>
							<td>${dokumenPekerjaan.sampai_tahun}</td>
							<td width="50%" class="text-center"><img loading="lazy" src='${dokumenPekerjaan.dokumen}' width="40px"></td>
						</tr>
					`
				}

				$("#dataPekerjaan").append(pekerjaan);



				let getContentAdmin = (user) => {
					let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
					return `
							<div style="width:100%">
								<div style="width:10%;" class="float-left">
									<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
								</div>
								<div class="float-left text-dark text-left" style="width:88%;">
									<div class="float-left" style="background:#dedede; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:0px 10px" id="commentUser">
										<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
										<small>${user.body}</small><br>
										<div class="text-right">
											<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
										</div>
									</div>
								</div>
							</div>
							`
				}

				let getContentUser = (user) => {
					let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
					return `
							<div style="width:100%">
								<div style="width:10%;" class="float-right">
									<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
								</div>
								<div class="float-right text-dark text-left" style="width:88%;">
									<div class="float-right" style="background:#e0e8fc; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:10px 0px" id="commentUser">
										<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
										<small>${user.body}</small><br>
										<div class="text-right">
											<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
										</div>
									</div>
								</div>
							</div>
							`
				}


				let local = localStorage.getItem(CONFIG.CACHE_KEY_OTENTIKASI);
				let dataLocal = JSON.parse(local);
				let UserID = dataLocal[0].id;
				let response = await ApiAnggota.getCommentar(id,"rekom_strttk")
				let dataComment = response.data;
				dataComment.reverse();
				let getAllContent = async () => {
					let elmHtml = ``
					await dataComment.forEach(async element => {
						elmHtml = elmHtml + (element.actor_type == "admin" ? getContentAdmin(element) : getContentUser(element))
					});
					return elmHtml
				}


				if (response.data == undefined || response.data.length <= 0) {
					document.getElementById("content-comment").innerHTML = `<span class="no-comment">Belum ada komentar</span>`
				} else {
					document.getElementById("content-comment").innerHTML = await getAllContent();

					setTimeout(() => {
						var elem = document.getElementById('content-comment');
						elem.scrollTop = elem.scrollHeight;
					}, 400);
				}

				$("#btnSendComment").on('click', async function () {
					if (UserID != '' && id != '') {
						document.getElementById("btnSendComment").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
						let content = document.getElementById("textKomentar").value
						const response = await ApiAnggota.sendCommentar({
							pengajuanID: id,
							contentComment: content,
							commentFor: 'rekom_strttk',
						});
						if (response.status_code == 200 || 201) {

							let getContentAdmin = (user) => {
								let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
								return `
										<div style="width:100%">
											<div style="width:10%;" class="float-left">
												<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
											</div>
											<div class="float-left text-dark text-left" style="width:88%;">
												<div class="float-left" style="background:#dedede; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:0px 10px" id="commentUser">
													<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
													<small>${user.body}</small><br>
													<div class="text-right">
														<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
													</div>
												</div>
											</div>
										</div>
										`
							}

							let getContentUser = (user) => {
								let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
								return `
										<div style="width:100%">
											<div style="width:10%;" class="float-right">
												<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
											</div>
											<div class="float-right text-dark text-left" style="width:88%;">
												<div class="float-right" style="background:#e0e8fc; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:10px 0px" id="commentUser">
													<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
													<small>${user.body}</small><br>
													<div class="text-right">
														<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
													</div>
												</div>
											</div>
										</div>
										`
							}


							let response = await ApiAnggota.getCommentar(id,"rekom_strttk")
							let dataComment = response.data;
							dataComment.reverse();

							let getAllContent = async () => {
								let elmHtml = ``
								await dataComment.forEach(async element => {
									elmHtml = elmHtml + (element.actor_type == "admin" ? getContentAdmin(element) : getContentUser(element))
								});
								return elmHtml
							}

							if (response.data == undefined || response.data.length <= 0) {
								document.getElementById("content-comment").innerHTML = `<span class="no-comment">Belum ada komentar</span>`
							} else {
								document.getElementById("content-comment").innerHTML = await getAllContent();

								setTimeout(() => {
									var elem = document.getElementById('content-comment');
									elem.scrollTop = elem.scrollHeight;
								}, 400);
							}
							document.getElementById("btnSendComment").innerHTML = `<i class="fas fa-comment"></i> Kirim`;
							$("#textKomentar").val('');
						} else {
							document.getElementById("btnSendComment").innerHTML = `<i class="fas fa-comment"></i> Kirim`;
							$("#textKomentar").val('');
						}


					}


					// $(this).off('click');

				});

				$('#detailSTRTTKModal').on('hidden.bs.modal', async function (e) {
					$('#btnSendComment').off('click');
				});


			}


		});

		$('#tablePengajuanSTRTTK tbody').on('click', '#delete', async function () {
			const table = $('#tablePengajuanSTRTTK').DataTable();
			let data = table.row($(this).parents('tr')).data();
			swal.fire({
				title: 'Hapus',
				html: "Anda ingin menghapus data ini? <br> <strong>Tanggal Pengajuan </strong>: " + data.created_at,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ya, Hapus!'
			}).then(async (result) => {
				if (result.value) {
					let idPendidikan = data.id;

					const deletePengajuanSTRTTK = await ApiAnggota.deletePengajuanSTRTTK({
						id: idPendidikan
					});

					if (deletePengajuanSTRTTK.status_code == '200') {
						swal.fire({
							title: 'Hapus',
							text: deletePengajuanSTRTTK.message,
							icon: 'success',
							showConfirmButton: false,
							timer: 1000
						});
						//refres manual 
						$('#tablePengajuanSTRTTK').DataTable().ajax.reload();
					} else {
						swal.fire({
							title: 'Error',
							text: deletePengajuanSTRTTK.message,
							icon: 'error',
							showConfirmButton: false,
							timer: 1000
						});
					}
				};
			});


		});


	},


	async _uploadBerkas() {
		let DzoneBerkasSTRTTK = new Dropzone("#form-upload-strttk", {
			url: "/file/post",
			uploadMultiple: false,
			autoDiscover: false,
			maxFiles: 1,
			maxFilesize: 1,
			autoQueue: false,
			acceptedFiles: ".jpeg,.jpg,.png,.gif",
			dictDefaultMessage: 'Drop image here (or click) to capture/upload',
			dictCancelUploadConfirmation: "Apa kamu yakin ingin menghapus foto ini?",
			previewTemplate: document.querySelector("#my-template").innerHTML,
			init: function () {
				this.on("maxfilesexceeded", function (file) {
					this.removeAllFiles();
					this.addFile(file);
				});
				this.on("error", function (file, message) {

					this.removeFile(file);
				});
				this.on("removeThumbnailCustom", function () {
					$('#form-upload-strttk .dz-preview:hidden').remove()
					$('#form-upload-strttk .dz-preview').fadeOut('slow');
					document.getElementById("form-upload-strttk").classList.remove('dz-started');
				});
			}
		});

		const eventUploadBerkas = async (e) => {
			e.preventDefault();
			$("#persyaratanSTRTTK").html('<td colspan="2">Loading ...</td>');
			$("#persyaratanSTRTTK").html('');
			let id = $("#textHIddenSTRTTK").val();
			document.getElementById("btnUpdateBerkas").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
			const uploadBerkasSTRTTK = await ApiAnggota.uploadBerkasSTRTTK({
				id: id,
				document_name: document.getElementById('selectBerkas').value,
				dokumen: (DzoneBerkasSTRTTK.files.length <= 0) ? null : await this.dataUrlToFile(DzoneBerkasSTRTTK.files[0].dataURL, DzoneBerkasSTRTTK.files[0].name)
			});
			if (uploadBerkasSTRTTK.status_code != 200) {
				NotificationModal.show(`${uploadBerkasSTRTTK.message}`, 'error');
				document.getElementById("btnUpdateBerkas").innerHTML = `<i class='fas fa-edit'></i> Kirim & Update Berkas`;
			} else {
				NotificationModal.show(`${uploadBerkasSTRTTK.message}`, 'success');
				document.getElementById("btnUpdateBerkas").innerHTML = `<i class='fas fa-edit'></i> Kirim & Update Berkas`;
			}

			const getDetailPengajuanSerkom = await ApiAnggota.getPengajuanSTRTTKByID({ id });

			let dataDetail = getDetailPengajuanSerkom.data;
			if (getDetailPengajuanSerkom.status_code == 200) {

				var dokumen = dataDetail.dokumen
				dokumen.forEach(dataBerkas => {
					var persyaratanSTRTTK = `
							<tr>
								<td>${dataBerkas.name}</td>
								<td width="50%" class="text-center"><img loading="lazy" src='${dataBerkas.dokumen_url}' width="40px"></td>
							</tr>
						`

					$("#persyaratanSTRTTK").append(persyaratanSTRTTK);
				});

			}

			DzoneBerkasSTRTTK.emit("removeThumbnailCustom");
			e.preventDefault();
		}
		document.getElementById('btnUpdateBerkas').addEventListener('click', eventUploadBerkas);
	},

	async _downloadSTRTTK() {
		$('#tablePengajuanSTRTTK tbody').on('click', '#rekomendasiSTRTTK', async function () {
			$(this).html('<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>');
			let table = $('#tablePengajuanSTRTTK').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let id = data.id;

			if (data.status != "Disetujui PD") {
				swal.fire({
					title: 'Warning !',
					html: "Status Permohonan : <strong><u>" + data.status + "</u></strong><br>Anda tidak dapat mendownload surat rekomendasi sebelum di setujui oleh Admin PD ",
					icon: 'warning',
					showCancelButton: false,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'OK'
				})
			} else {
				const downloadRekomSTRTTKPD = await ApiAnggota.downloadSTRTTKPD({ id });
			}

			$(this).html('<i class="fas fa-download"></i> Rekomendasi STRTTK');


		});
	},

	async _sendPengajuanSTRTTK() {
		let DzonePermohonanKetuaPC = new Dropzone("#form-upload-permohonan-ketua-pc", {
			url: "/file/post",
			uploadMultiple: false,
			autoDiscover: false,
			maxFiles: 1,
			maxFilesize: 1,
			autoQueue: false,
			acceptedFiles: ".jpeg,.jpg,.png,.gif",
			dictDefaultMessage: 'Drop image here (or click) to capture/upload',
			dictCancelUploadConfirmation: "Apa kamu yakin ingin menghapus foto ini?",
			previewTemplate: document.querySelector("#my-template").innerHTML,
			init: function () {
				this.on("maxfilesexceeded", function (file) {
					this.removeAllFiles();
					this.addFile(file);
				});
				this.on("error", function (file, message) {
					this.removeFile(file);
				});
				this.on("addedfiles", function (files) {
					this.emit("removeThumbnailCustom")
					this.removeAllFiles();
					this.addFile(files[0]);
				});
				this.on("removeThumbnailCustom", function () {
					$('#form-upload-permohonan-ketua-pc .dz-preview:hidden').remove()
					$('#form-upload-permohonan-ketua-pc .dz-preview').fadeOut('slow');
					document.getElementById("form-upload-permohonan-ketua-pc").classList.remove('dz-started');
				});
			}
		});

		let DzoneKodeEtik = new Dropzone("#form-upload-kode-etik", {
			url: "/file/post",
			uploadMultiple: false,
			autoDiscover: false,
			maxFiles: 1,
			maxFilesize: 1,
			autoQueue: false,
			acceptedFiles: ".jpeg,.jpg,.png,.gif",
			dictDefaultMessage: 'Drop image here (or click) to capture/upload',
			dictCancelUploadConfirmation: "Apa kamu yakin ingin menghapus foto ini?",
			previewTemplate: document.querySelector("#my-template").innerHTML,
			init: function () {
				this.on("maxfilesexceeded", function (file) {
					this.removeAllFiles();
					this.addFile(file);
				});
				this.on("error", function (file, message) {
					this.removeFile(file);
				});
				this.on("addedfiles", function (files) {
					this.emit("removeThumbnailCustom")
					this.removeAllFiles();
					this.addFile(files[0]);
				});
				this.on("removeThumbnailCustom", function () {
					$('#form-upload-kode-etik .dz-preview:hidden').remove()
					$('#form-upload-kode-etik .dz-preview').fadeOut('slow');
					document.getElementById("form-upload-kode-etik").classList.remove('dz-started');
				});
			}
		});

		let DzoneSuratSumpah = new Dropzone("#form-upload-surat-sumpah", {
			url: "/file/post",
			uploadMultiple: false,
			autoDiscover: false,
			maxFiles: 1,
			maxFilesize: 1,
			autoQueue: false,
			acceptedFiles: ".jpeg,.jpg,.png,.gif",
			dictDefaultMessage: 'Drop image here (or click) to capture/upload',
			dictCancelUploadConfirmation: "Apa kamu yakin ingin menghapus foto ini?",
			previewTemplate: document.querySelector("#my-template").innerHTML,
			init: function () {
				this.on("maxfilesexceeded", function (file) {
					this.removeAllFiles();
					this.addFile(file);
				});
				this.on("error", function (file, message) {
					this.removeFile(file);
				});
				this.on("addedfiles", function (files) {
					this.emit("removeThumbnailCustom")
					this.removeAllFiles();
					this.addFile(files[0]);
				});
				this.on("removeThumbnailCustom", function () {
					$('#form-upload-surat-sumpah .dz-preview:hidden').remove()
					$('#form-upload-surat-sumpah .dz-preview').fadeOut('slow');
					document.getElementById("form-upload-surat-sumpah").classList.remove('dz-started');
				});
			}
		});

		let DzoneKeteranganPraktik = new Dropzone("#form-upload-keterangan-praktik", {
			url: "/file/post",
			uploadMultiple: false,
			autoDiscover: false,
			maxFiles: 1,
			maxFilesize: 1,
			autoQueue: false,
			acceptedFiles: ".jpeg,.jpg,.png,.gif",
			dictDefaultMessage: 'Drop image here (or click) to capture/upload',
			dictCancelUploadConfirmation: "Apa kamu yakin ingin menghapus foto ini?",
			previewTemplate: document.querySelector("#my-template").innerHTML,
			init: function () {
				this.on("maxfilesexceeded", function (file) {
					this.removeAllFiles();
					this.addFile(file);
				});
				this.on("error", function (file, message) {
					this.removeFile(file);
				});
				this.on("addedfiles", function (files) {
					this.emit("removeThumbnailCustom")
					this.removeAllFiles();
					this.addFile(files[0]);
				});
				this.on("removeThumbnailCustom", function () {
					$('#form-upload-keterangan-praktik .dz-preview:hidden').remove()
					$('#form-upload-keterangan-praktik .dz-preview').fadeOut('slow');
					document.getElementById("form-upload-keterangan-praktik").classList.remove('dz-started');
				});
			}
		});

		let DzoneKeabsahanDokumen = new Dropzone("#form-upload-keabsahan-dokumen", {
			url: "/file/post",
			uploadMultiple: false,
			autoDiscover: false,
			maxFiles: 1,
			maxFilesize: 1,
			autoQueue: false,
			acceptedFiles: ".jpeg,.jpg,.png,.gif",
			dictDefaultMessage: 'Drop image here (or click) to capture/upload',
			dictCancelUploadConfirmation: "Apa kamu yakin ingin menghapus foto ini?",
			previewTemplate: document.querySelector("#my-template").innerHTML,
			init: function () {
				this.on("maxfilesexceeded", function (file) {
					this.removeAllFiles();
					this.addFile(file);
				});
				this.on("error", function (file, message) {
					this.removeFile(file);
				});
				this.on("addedfiles", function (files) {
					this.emit("removeThumbnailCustom")
					this.removeAllFiles();
					this.addFile(files[0]);
				});
				this.on("removeThumbnailCustom", function () {
					$('#form-upload-keabsahan-dokumen .dz-preview:hidden').remove()
					$('#form-upload-keabsahan-dokumen .dz-preview').fadeOut('slow');
					document.getElementById("form-upload-keabsahan-dokumen").classList.remove('dz-started');
				});
			}
		});

		const eventPengajuanSTRTTK = async (e) => {
			e.preventDefault();
			document.getElementById("btnKirimPengajuan").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`;

			var jenisPermohonan = 'renew';
			var perpanjangDengan = $('#textPerpanjangDengan').val();
			var nomorSTRTTK = $("#textNomorSTRTTK").val();
			var idPekerjaan = $("#selectSarana").val();
			var idPendidikan = $("#selectPendidikan").val();

			const sendPengajuanSTRTTK = await ApiAnggota.sendPengajuanSTRTTK({
				jenis_permohonan: jenisPermohonan,
				perpanjang_dengan: perpanjangDengan,
				nomor_strttk: nomorSTRTTK,
				pekerjaan_id: idPekerjaan,
				pendidikan_id: idPendidikan,
				surat_pernyataan_patuh_kode_etik: (DzoneKodeEtik.files.length <= 0) ? null : await this.dataUrlToFile(DzoneKodeEtik.files[0].dataURL, DzoneKodeEtik.files[0].name),
				surat_pernyataan_sumpah: (DzoneSuratSumpah.files.length <= 0) ? null : await this.dataUrlToFile(DzoneSuratSumpah.files[0].dataURL, DzoneSuratSumpah.files[0].name),
				surat_keterangan_praktik: (DzoneKeteranganPraktik.files.length <= 0) ? null : await this.dataUrlToFile(DzoneKeteranganPraktik.files[0].dataURL, DzoneKeteranganPraktik.files[0].name),
				surat_pernyataan_keabsahan_dokumen: (DzoneKeabsahanDokumen.files.length <= 0) ? null : await this.dataUrlToFile(DzoneKeabsahanDokumen.files[0].dataURL, DzoneKeabsahanDokumen.files[0].name),
				surat_permohonan_pc: (DzonePermohonanKetuaPC.files.length <= 0) ? null : await this.dataUrlToFile(DzonePermohonanKetuaPC.files[0].dataURL, DzonePermohonanKetuaPC.files[0].name)
			});
			if (sendPengajuanSTRTTK.status_code != 201) {
				NotificationModal.show(sendPengajuanSTRTTK.message, 'error');
				document.getElementById("btnKirimPengajuan").innerHTML = `<i class="fas fa-share"></i> Kirim Pengajuan`;
				$('#tablePengajuanSTRTTK').DataTable().ajax.reload();
				DzoneKeabsahanDokumen.emit("removeThumbnailCustom");
				DzoneKeteranganPraktik.emit("removeThumbnailCustom");
				DzoneKodeEtik.emit("removeThumbnailCustom");
				DzonePermohonanKetuaPC.emit("removeThumbnailCustom");
				DzoneSuratSumpah.emit("removeThumbnailCustom");
			} else {
				NotificationModal.show(`${sendPengajuanSTRTTK.message}`, 'success');
				document.getElementById("btnKirimPengajuan").innerHTML = `<i class="fas fa-share"></i> Kirim Pengajuan`;
				$('#tablePengajuanSTRTTK').DataTable().ajax.reload();
				DzoneKeabsahanDokumen.emit("removeThumbnailCustom");
				DzoneKeteranganPraktik.emit("removeThumbnailCustom");
				DzoneKodeEtik.emit("removeThumbnailCustom");
				DzonePermohonanKetuaPC.emit("removeThumbnailCustom");
				DzoneSuratSumpah.emit("removeThumbnailCustom");
			}
		}

		document.getElementById('btnKirimPengajuan').addEventListener('click', eventPengajuanSTRTTK);
		$('#btnClearPengajuanSTRTTK').click(async function () {
			document.getElementById("form-data-strttk").reset();
		});

	},


	async dataUrlToFile(dataUrl, fileName) {
		const res = await fetch(dataUrl);
		const blob = await res.blob();
		return new File([blob], fileName, { type: 'image/png' });
	},

	_navigateToLogin() {
		window.location.hash = '#/login';
	},


}

export default RekomSTRTTKPresenter;